import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';

import { UserContext } from 'contexts/UserContext';
import { ContactContext } from 'contexts/ContactContext';

import Button from 'components/Button/Button';

import { fetchLastActualiteCompte } from 'global/queries';

import { ReactComponent as Phone } from 'assets/phone.svg';
import { ReactComponent as Mail } from 'assets/mail.svg';

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import styles_button from 'components/Button/button.module.scss';
import styles from './home.module.scss';
import { Link } from 'react-router-dom';

import vitrinePic from '../../assets/default-page-vitrine.jpg';

const Annonceur = () => {
  const navigate = useNavigate();
  const {
    isPro,
    isBroker,
    user,
    stripeBilling,
    canSubscribeAbo,
    lastDisabledAbo
  } = useContext(UserContext);

  const { data: lastActu, status: lastActuStatus } = useQuery(
    'lastActualiteCompte',
    fetchLastActualiteCompte,
    {
      enabled: true,
      refetchOnWindowFocus: false
    }
  );

  const handleActuFancy = () => {
    Fancybox.show(
      [
        {
          html: `<div class='fActu'>
                  <div class='fEntete' style='background-image:url("${
                    lastActu.image
                      ? process.env.REACT_APP_SITE_URL +
                        `/photos/actualite_compte/${lastActu.image_cover}`
                      : 'https://via.placeholder.com/235x350/DADADA/DADADA/'
                  }")'>
                    <div class='fTitle'>${lastActu.titre}</div>
                  </div>
                  <div class='fContent'>
                      <picture>
                          <img
                            src=${
                              lastActu.image
                                ? process.env.REACT_APP_SITE_URL +
                                  `/photos/actualite_compte/${lastActu.image}`
                                : 'https://via.placeholder.com/235x350/DADADA/DADADA/'
                            }
                            draggable="false"
                          />
                        </picture>
                        <div class='fText'>${lastActu.texte}</div>
                  </div>
                </div>`
        }
      ],
      {
        hideScrollbar: true,
        dragToClose: false,
        mainClass: styles.fancyActu
      }
    );
  };

  return (
    <>
      <ServiceClient />
      {lastActu && (
        <div className={styles.tuile + ' ' + styles.actualite}>
          <div className={styles.title}>L'actualité de Geolocaux</div>
          <div className={styles.wrapped}>
            <div className={styles.entete}>{lastActu.titre}</div>
            <p>{lastActu.accroche}</p>
            <Button onClick={() => handleActuFancy()}>Voir l'actualité</Button>
          </div>
          <picture onClick={() => handleActuFancy()}>
            <img
              loading="lazy"
              src={
                lastActu.image_tiny
                  ? process.env.REACT_APP_SITE_URL +
                    `/photos/actualite_compte/${lastActu.image_tiny}`
                  : 'https://via.placeholder.com/235x350/DADADA/DADADA/'
              }
              draggable="false"
            />
          </picture>
        </div>
      )}
      {user.partenaire != null && user.partenaire.url != null ? (
        <div className={styles.tuile + ' ' + styles.vitrine}>
          <div className={styles.title}>
            Votre page vitrine
            <div className={styles.ico}>
              <picture>
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_SITE_URL +
                    '/photos/partenaires/logos/' +
                    user.partenaire.id +
                    '_on.jpg'
                  }
                  draggable="false"
                />
              </picture>
            </div>
          </div>
          <div className={styles.wrapped}>
            <div className={styles.innerWrapped}>
              <p>
                Pour la mettre à jour, contactez
                {(isPro || isBroker) && user?.commercial
                  ? ' ' +
                    user?.commercial?.prenom +
                    ' ! ' +
                    (user.commercial?.sexe == 'F' ? 'Elle' : 'Il')
                  : !!user?.consultant?.name
                  ? ` ${user.consultant.name} ! ` +
                    (user.consultant?.sexe == 'F' ? 'Elle' : 'Il')
                  : ' notre service client ! Il'}{' '}
                peut changer votre logo et les photos d'illustrations, mettre à
                jour les liens vers votre site et vos réseaux sociaux ou encore
                programmer la publication d'un article / d'une interview sur le
                blog de Geolocaux.
              </p>
              <a
                className={`${styles_button.button + ' ' + styles.button}`}
                href={`${process.env.REACT_APP_SITE_URL}${user.partenaire.url}`}
                target="_blank"
                rel="noreferrer"
              >
                Votre page vitrine
              </a>
            </div>
            <picture>
              <img
                loading="lazy"
                src={
                  !user.partenaire.photo
                    ? 'https://via.placeholder.com/315x168/DADADA/DADADA/'
                    : process.env.REACT_APP_SITE_URL +
                      '/photos/partenaires/big/' +
                      user.partenaire.photo
                }
                draggable="false"
              />
            </picture>
          </div>
        </div>
      ) : (
        user &&
        ((!user.from_flux && !!stripeBilling) ||
          (!stripeBilling &&
            canSubscribeAbo &&
            !!lastDisabledAbo &&
            lastDisabledAbo != null)) && (
          <div className={styles.tuile + ' ' + styles.stripPublish}>
            <div className={styles.title}>Publier plus d'annonces</div>
            <div className={styles.wrapped}>
              <div className={styles.innerWrapped}>
                <p>
                  Vous souhaitez diffuser plus de trois annonces ? <br />
                  Contacter notre service client pour obtenir une offre adaptée.
                  <br />
                  Vous pourrez également profiter de la mise en ligne d'une page
                  vitrine, de publications exclusives sur notre blog et du
                  référencement dans notre annuaire de professionnels de
                  l'immobilier d'entreprise.
                </p>
              </div>
              <picture>
                <img loading="lazy" src={vitrinePic} draggable="false" />
              </picture>
            </div>
          </div>
        )
      )}
      <div className={styles.tuile + ' ' + styles.abonnement}>
        <div className={styles.title}>Votre abonnement</div>
        <p>
          {(!user.from_flux && !!stripeBilling) ||
          (!stripeBilling &&
            canSubscribeAbo &&
            !!lastDisabledAbo &&
            lastDisabledAbo != null) ? (
            <>
              Retrouvez les informations liées à votre abonnement et vos
              factures dans un espace dédié et sécurisé.
            </>
          ) : (
            <>
              Retrouvez votre contrat, les informations liées à votre abonnement
              et vos factures dans un espace dédié et sécurisé.
            </>
          )}
        </p>
        <Link
          to="/profil"
          rel="noreferrer"
          className={`${styles_button.button} ${styles.button}`}
        >
          Votre abonnement
        </Link>
      </div>
    </>
  );
};

export default Annonceur;

export const ServiceClient = () => {
  const { setShowFormContact } = useContext(ContactContext);
  const { isPro, isBroker, user, contactTel, contactPicture } =
    useContext(UserContext);
  return (
    <div className={styles.tuile + ' ' + styles.service_client}>
      <div className={styles.title}>Votre service client</div>
      <div className={styles.descript}>
        <div
          className={styles.avatar}
          style={{
            backgroundImage: `url(${contactPicture})`
          }}
        ></div>
        <p>
          {(isPro || isBroker) && user?.commercial
            ? ' ' +
              user?.commercial?.prenom +
              ', votre ' +
              (user.commercial?.sexe == 'F'
                ? 'interlocutrice dédiée,'
                : 'interlocuteur dédié,')
            : !!user?.consultant?.name
            ? ` ${user.consultant.name} votre ` +
              (user.consultant?.sexe == 'F'
                ? 'interlocutrice dédiée,'
                : 'interlocuteur dédié,')
            : 'Notre service client'}{' '}
          se tient à votre disposition pour échanger par mail ou par téléphone.
        </p>
      </div>
      <div className={styles.actions}>
        <div
          className={styles.phone}
          onClick={(_) => {
            document.location.href = `tel:${contactTel.replace(/\s/g, '')}`;
          }}
        >
          <div className={styles.ac_icon}>
            <Phone />
          </div>
          <div className={styles.ac_wrap}>
            <span>{contactTel}</span>
          </div>
        </div>
        <div
          className={styles.mail}
          onClick={() => setShowFormContact((prev) => !prev)}
        >
          <div className={styles.ac_icon}>
            <Mail />
          </div>
          <div className={styles.ac_wrap}>Contact par mail</div>
        </div>
      </div>
    </div>
  );
};
