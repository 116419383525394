import { ReactComponent as HomeSVG } from 'assets/accueil.svg';
import { ReactComponent as HomeWhiteSVG } from 'assets/accueil-white.svg';
import { ReactComponent as ProjetsSVG } from 'assets/projet.svg';
import { ReactComponent as ProjetsWhiteSVG } from 'assets/projet-white.svg';
import { ReactComponent as StatsSVG } from 'assets/stat.svg';
import { ReactComponent as StatsWhiteSVG } from 'assets/stat-white.svg';
import { ReactComponent as AlerteSVG } from 'assets/alerte.svg';
import { ReactComponent as AlerteWhiteSVG } from 'assets/alerte-white.svg';
import { ReactComponent as AnnonceSVG } from 'assets/annonce.svg';
import { ReactComponent as AnnonceWhiteSVG } from 'assets/annonce-white.svg';
import { ReactComponent as TransactionSVG } from 'assets/transaction.svg';
import { ReactComponent as TransactionWhiteSVG } from 'assets/transaction-white.svg';
import { ReactComponent as DemandeSVG } from 'assets/demande.svg';
import { ReactComponent as DemandeWhiteSVG } from 'assets/demande-white.svg';
import { ReactComponent as FavoriSVG } from 'assets/favori.svg';
import { ReactComponent as FavoriWhiteSVG } from 'assets/favori-white.svg';
import { ReactComponent as GeoIntelligenceSVG } from 'assets/geointelligence.svg';
import { ReactComponent as GeoIntelligenceWhiteSVG } from 'assets/geointelligence-white.svg';
import { ReactComponent as UserSVG } from 'assets/homme.svg';
import { ReactComponent as UserWhiteSVG } from 'assets/homme-white.svg';

export const availablesMenus = (pathname, user) => {
  let menus = [];
  let {
    is_pro: isPro,
    is_broker: isBroker,
    has_partenaire: hasPartenaire,
    transaction_enable: transactionEnable,
    has_children: hasChildren,
    has_children_partenaire: hasChildrenPartenaire,
    has_etude: hasEtude,
    several_project: severalProject,
    first_project_uri: firstProjectUri
  } = user;

  menus.push({
    key: 'accueil',
    to: '/accueil',
    exact: true,
    isActive: () => pathname == '/accueil',
    footer: (
      <>{pathname == '/accueil' ? <HomeWhiteSVG /> : <HomeSVG />} Accueil</>
    ),
    navbar: 'Accueil',
    global: 'Accueil'
  });

  if (isPro && !isBroker) {
    menus.push({
      key: 'stats',
      to: '/stats',
      exact: true,
      isActive: () => pathname == '/stats',
      footer: (
        <>{pathname == '/stats' ? <StatsWhiteSVG /> : <StatsSVG />} Stats</>
      ),
      navbar: 'Statistiques',
      global: 'Statistiques'
    });
  }

  if (isPro) {
    let isActive =
      pathname == '/annonces' ||
      pathname.match(
        /^\/annonces|annonces(\/[0-9]+\/(edit))|nouvelle-annonce?$/g
      );
    menus.push({
      key: 'annonces',
      to: '/annonces',
      exact: true,
      isActive: () => isActive,
      footer: <>{isActive ? <AnnonceWhiteSVG /> : <AnnonceSVG />} Annonces</>,
      navbar: 'Annonces',
      global: 'Annonces'
    });
  }

  if (!isPro) {
    menus.push({
      key: 'demandes',
      to: '/demandes',
      exact: true,
      isActive: () => pathname == '/demandes',
      footer: (
        <>
          {pathname == '/demandes' ? <DemandeWhiteSVG /> : <DemandeSVG />}{' '}
          Demandes
        </>
      ),
      navbar: 'Demandes',
      global: 'Demandes'
    });
  }

  if (!isPro) {
    let isActive = pathname.match(
      /^\/nouveau-projet|projets(\/[0-9]+(\/settings)?)?$/g
    );
    menus.push({
      key: 'projets',
      to: `${!!firstProjectUri ? firstProjectUri : '/projets'}`,
      exact: false,
      isActive: () => isActive,
      footer: <>{isActive ? <ProjetsWhiteSVG /> : <ProjetsSVG />} Projets</>,
      navbar: `Projet${!!firstProjectUri && firstProjectUri != '' ? '' : 's'}`,
      global: `Projet${!!firstProjectUri && firstProjectUri != '' ? '' : 's'}`
    });
  }
  if (!isPro || isBroker) {
    let isActive = pathname.match(
      /^\/nouvelle-alerte|alertes(\/[0-9]+\/settings)?$/g
    );
    menus.push({
      key: 'alertes',
      to: '/alertes',
      exact: false,
      isActive: () => isActive,
      footer: <>{isActive ? <AlerteWhiteSVG /> : <AlerteSVG />} Alertes</>,
      navbar: 'Alertes',
      global: 'Alertes'
    });
  }

  if (hasPartenaire || hasChildrenPartenaire) {
    //&& !hasChildren suite au ticket #4341
    let isActive = pathname.match(
      /^\/transactions|transactions(\/[0-9]+\/(edit))|nouvelle-transaction?$/g
    );
    menus.push({
      key: 'transactions',
      to: '/transactions',
      exact: false,
      isActive: () => isActive,
      footer: (
        <>
          {isActive ? <TransactionWhiteSVG /> : <TransactionSVG />} Transactions
        </>
      ),
      navbar: 'Transactions',
      global: 'Transactions'
    });
  }

  if (isPro && !hasChildren && !!user.module) {
    menus.push({
      key: 'geointelligence',
      to: '/geointelligence',
      exact: true,
      isActive: () => pathname == '/geointelligence',
      footer: null,
      navbar: 'Geointelligence',
      global: 'Geointelligence'
    });
  }
  if (!isPro || (isPro && isBroker)) {
    let isActive = pathname.match(/^\/(selections|favoris)?$/g);
    menus.push({
      key: 'favoris',
      to: isPro ? '/selections' : '/favoris',
      exact: true,
      isActive: () => isActive,
      footer: (
        <>
          {isActive ? <FavoriWhiteSVG /> : <FavoriSVG />}
          {' ' + (isPro ? 'Sélections' : 'Favoris')}
        </>
      ),
      navbar: isPro ? 'Sélections' : 'Favoris',
      global: isPro ? 'Sélections' : 'Favoris'
    });
  }

  if (hasEtude && !(isPro && !hasChildren && !!user.module)) {
    let isActive = pathname.match(/^\/(etudes)?$/g);
    menus.push({
      key: 'etudes',
      to: '/etudes',
      exact: false,
      isActive: () => isActive,
      footer: null,
      navbar: 'Mes employés',
      global: 'Mes employés'
    });
  }

  menus.push({
    key: 'profil',
    to: '/profil',
    exact: true,
    isActive: () =>
      pathname == '/profil' || pathname == '/factures' || pathname == '/devis',
    footer: (
      <>{pathname == '/profil' ? <UserWhiteSVG /> : <UserSVG />} Profil</>
    ),
    navbar: 'Paramètres',
    global: 'Informations'
  });

  return menus;
};
